<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="product_id" label="商品">
            <a-select v-model="form.product_id" @change="changeGoods">
              <a-select-option v-for="val in productList" :key="val.id" :value="val.id">
                {{ val.product_name }} ({{ val.product_id }})
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="date" label="批次">
            <a-select v-model="form.date" style="width: 100%" @change="changeBatch">
              <a-select-option
                      v-for="(val, index) in BatchListInfo.pici"
                      :key="val.batch"
                      :value="val.batch"
              >
                {{ val.batch }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="number" label="编号">
            <a-select v-model="form.number" style="width: 100%">
              <a-select-option
                      v-for="val in numberList"
                      :key="val"
                      :value="val"
              >
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item label="标题1">
            <a-input value="原料验收" disabled />
          </a-form-model-item>
          <a-form-model-item prop="result1" label="描述">
            <a-select mode="multiple" v-model="form.result1">
              <a-select-option v-for="(val, index) in results1" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image1" label="上传图片">
            <Upload :ID_image="form.image1" :count="3" @handleChange="handleChange" />
          </a-form-model-item>
          <a-form-model-item label="标题2">
            <a-input value="生产投料" disabled />
          </a-form-model-item>
          <a-form-model-item prop="result2" label="描述">
            <a-select mode="multiple" v-model="form.result2">
              <a-select-option v-for="(val, index) in results2" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image2" label="上传图片">
            <Upload :ID_image="form.image2" :count="3" @handleChange="handleChange2" />
          </a-form-model-item> -->
          <a-form-model-item label="标题1">
            <a-input value="半成品检测" disabled />
          </a-form-model-item>
          <a-form-model-item prop="result3" label="描述">
            <a-select mode="multiple" v-model="form.result3">
              <a-select-option v-for="(val, index) in results3" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image3" label="上传图片">
            <Upload :ID_image="form.image3" :count="3" @handleChange="handleChange3" />
          </a-form-model-item>
          <a-form-model-item label="标题2">
            <a-input value="成品检测" disabled />
          </a-form-model-item>
          <a-form-model-item prop="result4" label="描述">
            <a-select mode="multiple" v-model="form.result4">
              <a-select-option v-for="(val, index) in results4" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image4" label="上传图片">
            <Upload :ID_image="form.image4" :count="3" @handleChange="handleChange4" />
          </a-form-model-item>
          <a-form-model-item label="标题3">
            <a-input value="成品出库" disabled />
          </a-form-model-item>
          <a-form-model-item prop="result5" label="描述">
            <a-select mode="multiple" v-model="form.result5">
              <a-select-option v-for="(val, index) in results5" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image5" label="上传图片">
            <Upload :ID_image="form.image5" :count="3" @handleChange="handleChange5" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import Upload from '../../../components/Upload/index'
  import { traceabilityAdd, traceabilityEdit, fromProductList, tracePici } from "@/api/from";

export default {
  name: "FormModal",
  props: ["visible", "form", "BatchList", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  components: {
    Upload
  },
  data() {
    return {
      numberList: [],
      BatchListInfo: [],
      data: [
        {
          title: '原料验收',
          result: [],
          image: []
        },
        {
          title: '生产投料',
          result: [],
          image: []
        },
        {
          title: '半成品检测',
          result: [],
          image: []
        },
        {
          title: '成品检测',
          result: [],
          image: []
        },
        {
          title: '成品出库',
          result: [],
          image: []
        }
      ],
      productList: [],
      uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
      fileList1: [],
      results1: [
        { label: '原料索证齐全', value: '原料索证齐全' },
        { label: '原料验收合格', value: '原料验收合格' }
      ],
      results2: [
        { label: '根据订单生产', value: '根据订单生产' }
      ],
      results3: [
        { label: '半成品检测合格', value: '半成品检测合格' }
      ],
      results4: [
        { label: '成品检测合格', value: '成品检测合格' }
      ],
      results5: [
        { label: '成品出库检测合格', value: '成品出库检测合格' }
      ],
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        product_id: [
          { required: true, message: "请选择商品", trigger: "change" }
        ],
        date: [
          { required: true, message: "请选择批次", trigger: "change" }
        ],
        number: [{ required: true, message: "请输入编号", trigger: "blur" }],
        result1: [
          { required: true, message: "请输入描述", trigger: "blur" }
        ]
      },
      loading: false,
    };
  },
  watch: {
    'BatchList': {
      handler() {
        this.BatchListInfo = this.BatchList
      },
      deep: true
    },
  },
  created() {
    fromProductList({ page: 1, page_size: 9999 })
    .then((data) => {
      this.productList = data.data;
    })
  },
  methods: {
    async changeBatch(val) {
      delete this.form.number
      this.numberList = this.BatchList.pici.filter(obj => obj.batch == val).map(obj => obj.number).flat();
    },
    async changeGoods(val) {
      let res1 = await tracePici({ product_id: val });
      delete this.form.date
      delete this.form.number
      this.BatchListInfo = res1.data;
      this.BatchList.pici = res1.data.pici
    },
    handleChange(val) {
      this.$set(this.form,'image1',val);
    },
    handleChange2(val) {
      this.$set(this.form,'image2',val);
    },
    handleChange3(val) {
      this.$set(this.form,'image3',val);
    },
    handleChange4(val) {
      this.$set(this.form,'image4',val);
    },
    handleChange5(val) {
      this.$set(this.form,'image5',val);
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let form = this.form
          this.loading = true;
          let func = form.id ? traceabilityEdit : traceabilityAdd;
          this.data[0].result = form.result1
          this.data[1].result = form.result2
          this.data[2].result = form.result3
          this.data[3].result = form.result4
          this.data[4].result = form.result5
          this.data[0].image = form.image1?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          this.data[1].image = form.image2?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          this.data[2].image = form.image3?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          this.data[3].image = form.image4?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          this.data[4].image = form.image5?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          form.data = this.data;
          delete form.image3
          delete form.image4
          delete form.image5
          func(form)
            .then((res) => {
              if(res.code == 200){
                this.$message.success(form.id ? "修改成功" : "新增成功");
                this.$emit(form.id ? "update" : "create");
                this.cancel();
              } else {
                this.$message.error(res.message)
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.numberList = []
      // if(!this.form.id) {
      //   this.$refs.form.resetFields();
      // }
    },
  },
};
</script>

<style scoped></style>
